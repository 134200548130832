<template>
  <div class="list cl">
    <div class="list-nav">
      <div class="column-name">{{ columnName }}</div>
      <ul>
        <li v-for="(item, index) in columnList" :key="index" @click="chageColumn(item.name, index)" :class="[$route.params.num == item.name || $route.params.num==index? 'active' : '']">{{ item.name }}</li>
      </ul>
    </div>
    <div class="list-con">
      <div class="cl">
        <div class="column-title">{{ currentName }}</div>
        <div class="bread">
          <span>首页&nbsp;>&nbsp;</span>
          <span v-if="columnName !== currentName">{{ columnName }}&nbsp;>&nbsp;</span>
          <span>{{ currentName }}</span>
        </div>
      </div>
      <ul v-if="!paginationValue">
        <li v-for="(item, index) in list" :key="index">
          <i></i>
          <span @click="goDetail(item.id)">
            <div v-if="$route.params.num==0&&$route.params.type=='新闻与公告'||$route.params.num=='全部'&&$route.params.type=='新闻与公告'" style="display: inline-block">【{{ item.belong }}】</div>
            {{ item.title }}
          </span>
          <span>{{ conversionTimestamp(item.riqi) }}</span>
        </li>
      </ul>
      <div v-else style="margin-top: 100px;text-align: center">暂无数据</div>
      <div class="pagination">
        <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="paginationValue" :current-page.sync="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getListT, score } from "../api/api";
import { menudata } from "../navJSON/nav";

export default {
  name: "list",
  data() {
    return {
      currentPage: 1,
      total: 0,
      pageSize: 10,
      list: [],
      columnList: [],
      currentID: "",
      currentName: "",
      paginationValue: false,
      columnName: "",
      loop: false
    };
  },
  methods: {
    init() {
      this.getNavSec();
      this.getTitle();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let params;
      if (this.$route.params.type == "新闻与公告") {
        if (this.$route.params.num == "全部" || this.$route.params.num == "0") {
          params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}`;
        } else {
          params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}&type=${this.$route.params.num}`;
        }
        this.getListT(params);
      } else {
        let params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}&type=${this.$route.params.type}`;
        this.getList(params);
      }
    },
    // 判断是否有二级
    getNavSec() {
      menudata.forEach(item => {
        if (this.$route.params.type == item.name) {
          this.columnName = item.name;
          if (item.children) {
            this.columnList = item.children;
            let params;
            if (
              this.$route.params.num == "全部" ||
              this.$route.params.num == 0
            ) {
              params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}`;
            } else {
              params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}&type=${this.$route.params.num}`;
            }
            this.getListT(params);
          } else {
            this.columnList = [];
            let params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}&type=${this.$route.params.type}`;
            this.getList(params);
          }
        }
      });
    },
    chageColumn(name, index) {
      this.currentPage = 1;
      this.$router.push({
        name: "list",
        params: { type: this.columnName, num: name }
      });
      this.currentName = name;
      let params;
      if (index !== 0) {
        params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}&type=${name}`;
      } else {
        params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}`;
      }
      this.getListT(params);
    },
    // 获取有二级的列表
    getListT(params) {
      getListT(params).then(res => {
        this.list = res.result.data;
        this.total = res.result.count;
        if (this.total !== 0) {
          this.paginationValue = false;
        } else {
          this.paginationValue = true;
        }
      });
    },
    //获取一级列表
    getList(params) {
      score(params).then(res => {
        this.list = res.result.data;
        this.total = res.result.count;
        if (this.total !== 0) {
          this.paginationValue = false;
        } else {
          this.paginationValue = true;
        }
      });
    },
    goDetail(id) {
      this.$router.push({
        name: "detail",
        params: { id: id, type: this.columnName }
      });
    },
    getTitle() {
      menudata.forEach(item => {
        if (this.$route.params.type == item.name) {
          this.columnName = item.name;
          if (item.children) {
            item.children.forEach(it => {
              if (
                this.$route.params.num == it.name ||
                this.$route.params.num == it.num
              ) {
                this.currentName = it.name;
              }
            });
          } else {
            this.currentName = item.name;
          }
        }
      });
    },
    conversionTimestamp(timestamp) {
      timestamp = parseInt(timestamp);
      var date = new Date(timestamp);
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return date.getFullYear() + "-" + month + "-" + day;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    $route() {
      this.currentPage = 1;
      this.getNavSec();
      this.getTitle();
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/list";
</style>
